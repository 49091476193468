<script>
import Component, { mixins } from 'vue-class-component';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import SubHeader from '@/core/shared/components/SubHeader';
import Loading from '@/core/shared/components/Loading';
import BottomBar from '@/core/shared/components/BottomBar';
import UIButton from '@/core/shared/components/ui/UIButton';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
const confirmationMessage = 'The experience is currently saving, please wait until it is finished';

@Component({
  components: {
    SubHeader,
    BottomBar,
    UIButton,
    BreadcrumbNavConnected,
    Loading,
  },
  props: {
    id: {
      required: true,
    },
    locationId: {
      required: true,
    },
  },
})
export default class ExperiencesShow extends mixins(FocusModeMixin) {
  get title () {
    return this.isNew ? this.$t('New Experience') : this.$t('Edit Experience');
  }

  experience = null
  launchers = null
  saving = false
  fetching = false
  routeComponentsValidity = {}

  get isNew () {
    return !this.id;
  }

  get isInvalid () {
    let isValid = !!Object.keys(this.routeComponentsValidity).length;
    for (const key in this.routeComponentsValidity) {
      if (!Object.prototype.hasOwnProperty.call(this.routeComponentsValidity, key)) continue;

      isValid &= this.routeComponentsValidity[key];
    }
    // NOTE(Jack): I couldn't actually put the !Boolean(isValid) on one line because of the extremely strict linting rules
    isValid = Boolean(isValid);
    return !isValid;
  }

  get preventDeletion () {
    return this.$store.getters['entities/Experience'](experience => this.locationId === experience.location.id).length === 1;
  }

  experienceUpdated (localExperience, routeComponentsName, routeComponentsValidity) {
    if (routeComponentsName) this.$set(this.routeComponentsValidity, routeComponentsName, routeComponentsValidity);
  }

  destroyed () {
    this.$store.commit('setLoadingOverlay', false);
    window.removeEventListener('beforeunload', this._beforeUnloadHandler);
  }

  setBreadcrumb () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$store.getters['entities/Location'](this.locationId).title,
      },
      {
        name: this.$t('Experiences'),
        routerLink: { name: 'experiences-index', params: { locationId: this.locationId } },
      },
      {
        name: this.$t('Edit Experiences'),
      },
    ]);
  }

  async created () {
    this.$store.commit('setTitle', this.title);
    this.$watch(() => { return this.saving || this.fetching; }, (val) => {
      if (val) {
        this.$store.commit('setLoadingOverlay', true);
      } else {
        this.$store.commit('setLoadingOverlay', false);
      }
    });
    this.setBreadcrumb();

    this._beforeUnloadHandler = e => {
      if (this.saving) {
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    };
    window.addEventListener('beforeunload', this._beforeUnloadHandler);
    this.fetching = true;
    const [, { launchers }] = await Promise.all([this.$store.dispatch('getLocationExperiences', this.locationId), this.$store.dispatch('getLaunchers')]);

    this.prefillExperience(launchers[0]);
    this.launchers = launchers;

    if (!this.isNew) {
      const { experience, launchers } = await this.$store.dispatch('getExperienceDetails', this.id);

      this.experience = { ...this.experience, ...experience };
      this.launchers = launchers;
      if (!this.experience.launcher) {
        this.experience.launcher.id = this.launchers[0].id;
      } // set default launcher
    }
    this.fetching = false;
  }

  prefillExperience (launcher) {
    this.experience = {
      banners: [],
      tiers: [{ price: 0, length: 60 }],
      hours: [{ monday: true, saturday: true, sunday: true, thursday: true, tuesday: true, wednesday: true, friday: true, open: '08:00:00', close: '20:00:00' }],
      customHours: [],
      type: 'non-vr',
      equipment: '',
      bookingMinimumWindowTime: 0,
      location: { id: this.locationId },
      launcher: {
        id: launcher.id,
      },
      bookingSetupTime: 0,
      bookingCushionTime: 15,
      bookingIncrementTime: 15,
      maxPeoplePerStation: 4,
      cancelHours: 24,
      launcherTrending: true,
      launcherRecentlyAdded: true,
      launcherDifficulty: true,
      launcherGenre: true,
      gameReviewsEnabled: true,
    };
  }

  async saveExperience () {
    this.saving = true;
    try {
      const experience = await this.$store.dispatch('saveExperience', this.experience);
      this.experience = experience;
      if (this.isNew) {
        this.$store.commit('setExperience', experience);
      }
      this.$store.commit('setFlash', {
        message: `${this.experience.title} ${this.$t('saved')}`,
        type: 'success',
      });
    } finally {
      this.saving = false;
    }
    this.$router.push({ name: 'experiences-index', params: { locationId: this.locationId } });
  }

  deleteExperience () {
    this.$store.commit('setConfirm', {
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            this.saving = true;
            try {
              await this.$store.dispatch('deleteExperience', this.experience);
              this.$store.commit('unsetExperience', this.experience);
              this.$store.commit('setFlash', {
                message: `${this.experience.title} ${this.$t('deleted')}`,
                type: 'success',
              });
            } catch (e) {
              this.$store.commit('setFlash', {
                message: `${this.experience.title} ${this.$t('couldn’t be deleted')}`,
                type: 'error',
              });
            } finally {
              this.saving = false;
              this.$router.replace({ name: 'experiences-index' });
            }
          },
        },
      ],
      icon: 'icon-alert-triangle',
      title: this.$t('Are you sure you want to delete this experience?'),
      message: this.$t('This will reassign all the stations to another experience'),
    });
  }
}
</script>

<template>
  <div class="experiences-index relative">
      <!-- TODO(Jack): Add validation here-->
      <sub-header>
          <ul class="tabs" slot="tabs">
              <router-link tag="li" :to="{ name: 'experience-details' }">{{ $t('Experience Details') }}</router-link>
              <router-link v-if="$features['expanded-experience-settings'] && experience && experience.type === 'vr'" tag="li" :to="{ name: 'experience-launcher-settings' }">{{ $t('Launcher Settings') }}</router-link>
              <router-link v-if="$permissions['education'] || $permissions['enterprise-operator']" tag="li" :to="{ name: 'experience-pricing' }">{{ $t('Hours') }}</router-link>
              <router-link v-else tag="li" :to="{ name: 'experience-pricing' }">{{ $t('Pricing & Hours') }}</router-link>
              <router-link v-if="!$permissions['education'] &amp;&amp; !$permissions['enterprise-operator']" tag="li" :to="{ name: 'experience-refunds' }">{{ $t('Refunds & Cancellations') }}</router-link>
              <router-link v-if="!$permissions['education'] &amp;&amp; !$permissions['enterprise-operator']" tag="li" :to="{ name: 'experience-confirmation-text' }">{{ $t('Booking Confirmation Text') }}</router-link>
              <router-link v-if="!$permissions['education'] &amp;&amp; !$permissions['enterprise-operator']" tag="li" :to="{ name: 'booking-config' }">{{ $t('Booking Configs') }}</router-link>
          </ul>
      </sub-header>
      <div class="container">
          <div class="top-actions">
              <BreadcrumbNavConnected class="breadcrumb"></BreadcrumbNavConnected>
              <UIButton class="deletebtn" icon="trash" tiny="tiny" @click="deleteExperience" v-if="!isNew &amp;&amp; !preventDeletion">{{ $t('Delete experience') }}</UIButton>
          </div>
          <router-view :experience.sync="experience" :launchers="launchers" @update:experience="experienceUpdated" @delete="deleteExperience" :loading="fetching" :isOrganizationEducation="$permissions['education'] || $permissions['enterprise-operator']" :typeOfStation="experience && experience.type === 'vr' ? 'station' : 'experience'"></router-view>
          <bottom-bar :back-router-link="{ name: 'experiences-index' }" @save="saveExperience" :valid="!(saving || fetching || isInvalid)" :action-text="isNew ? $t('Create') : $t('Save')"></bottom-bar>
      </div>
  </div>
</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.experiences-index {
  @apply --body;

  & .container {
    @apply --contentContainer;
  }
  & .experiencecard {
    cursor: pointer;
    min-height: 27rem;
  }
  & .newbtn {
    @apply --buttonPrimary;
    font-weight: bold;
    grid-area: b;
    justify-self: flex-end;
  }
  & .typeselect {
    @apply --select;
    grid-area: a;
  }
  & .breadcrumb {
    margin-right: auto;
  }
  & .top-actions {
    display: flex;
    margin-bottom: var(--spacingSm);

    @media (--desktop) {
      margin-bottom: var(--spacingMd);
    }
  }
}
</style>
